import React from "react";
import "./App.css";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap.js";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  HashRouter,
} from "react-router-dom";
import Home from "./Pages/Home/Home";
import About from "./Pages/About/About";
import Service from "./Pages/Service/FinService";
import Newsroom from "./Pages/Newsroom/Newsroom";
import ViewNewsroom from "./Pages/Newsroom/ViewNewsroom";
import ScrollToTop from "./Components/ScrollTop/scrollToTop";
import FleetService from "./Pages/Service/FleetService";
import DeliveryService from "./Pages/Service/DeliveryService";
import CareerView from "./Pages/Career/CareerView";
import Career from "./Pages/Career/Career";
import Contact from "./Pages/Contact/Contact";
import Tech from "./Pages/Tech/Tech";
import SplashScreen from "./Components/SplashScr/SplashScreen"
import { useState,useEffect } from "react";
import {config} from './config';
import BlogDetail from "./Pages/Newsroom/BlogDetail";

function App() {
    const [loading, setLoading] = useState(true);
  const pathSegments = window.location.pathname.split("/");
  const baseUrl = window.location.origin + "/" + pathSegments[1];
    useEffect(() => {
      setTimeout(() => {
        setLoading(false);
      }, 2000);
    }, []);


  console.log(baseUrl);
  return (
    <div className="App">
      {loading ? (
        <SplashScreen />
      ) : (
        <Router basename={config.BASE_PATH}>
          <ScrollToTop />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route
              path="/FinService"
              element={<Service />}
            />
            <Route path="/BlogDetail/:id" element={<BlogDetail />} />

            <Route path="/Tech" element={<Tech />} />
            <Route path="/Career" element={<Career />} />
            <Route
              path="/Newsroom"
              element={<Newsroom />}
            />
            <Route path="/Contact" element={<Contact />} />
            <Route
              path="/ViewNewsroom/:newsroomid"
              element={<ViewNewsroom />}
            />
            <Route
              path="/DeliveryService"
              element={<DeliveryService />}
            />
            <Route
              path="/FleetService"
              element={<FleetService />}
            />
            <Route
              path="/CareerView/:careerid"
              element={<CareerView />}
            />
          </Routes>
        </Router>
      )}
    </div>
  );
}

export default App;
