import React, { useState, useEffect } from "react";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import "./Loan.css";
import { api } from "../../Api/Api";
import axios from "axios";

const Loan = (props) => {
  console.log("load");
  const [emailSent, setEmailSent] = useState(false);
  const [loader, serLoader] = useState(false);
  const SignupSchema = Yup.object().shape({
    name: Yup.string()
      .trim()
      .nullable("")
      .matches(/^[a-zA-Z]+$/, "Name field can not be empty")
      .min(2, "Too Short!")
      .max(50, "Too Long!")
      .required("Required"),
    mobile: Yup.string()
      .matches(/^\d{3}\d{10}$/, "Invalid mobile number")
      .required("Mobile number is required"),
    // email: Yup.string().email('Invalid email').required('Required'),
    location: Yup.string()
      .trim()
      .nullable("")
      .matches(/^[a-zA-Z]+$/, "Name field can not be empty")
      .min(2, "Too Short!")
      .max(50, "Too Long!")
      .required("Required"),
  });

  const [open, setOpen] = useState(props.openProps);

  const [modalname, setModalName] = useState(props.pop);

  useEffect(() => {
    console.log("true");
    setOpen(props.openProps);
  }, [props.openProps]);

  const onCloseModal = () => {
    console.log("false");
    setOpen(false);
  };

  return (
    <>
      <div className="form_modal">
        <Modal open={open} onClose={onCloseModal} center>
          <div className="row">
            <div className="col-12 text-center form_modal-title">
              <h2>{modalname}</h2>
              <p>
                {" "}
                Your email address will not be published. Required fields are
                marked *
              </p>
            </div>
          </div>

          <Formik
            initialValues={{
              name: "",
              mobile: "247",
              location: "",
            }}
            validationSchema={SignupSchema}
            onSubmit={(values, { resetForm }) => {
              // same shape as initial values
              console.log(values);
              serLoader(true);

              axios
                .request(api("carFinance/createCarFinance", "POST", values))
                .then(function (response) {
                  setEmailSent(true);
                  resetForm();
                  serLoader(false);
                  setTimeout(() => {
                    setEmailSent(false);
                  }, 5000);
                })
                .catch(function (error) {
                  console.error(error);
                });
            }}
          >
            {({ errors, touched }) => (
              <Form className="form_modal-card">
                <div className="row">
                  <div className="col-12 col-sm-6">
                    <div class="mb-3">
                      <label for="" class="form-label">
                        Your name *
                      </label>
                      <Field
                        name="name"
                        type="text"
                        placeholder="Full Name"
                        className="form-control"
                      />
                      {errors.name && touched.name ? (
                        <div className="form_error pt-1">{errors.name}</div>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-12 col-sm-6">
                    <div class="mb-3">
                      <label for="" class="form-label">
                        Your Number *
                      </label>

                      <Field name="mobile" placeholder="Phone Number">
                        {({ field, form }) => (
                          <div>
                            <PhoneInput
                              {...field}
                              country="cm"
                              disableDropdown
                              disableCountryCode
                              onChange={(value) =>
                                form.setFieldValue("mobile", value)
                              }
                              containerClass="phone-input-container"
                            />
                          </div>
                        )}
                      </Field>

                      {errors.mobile && touched.mobile ? (
                        <div className="form_error pt-1">{errors.mobile}</div>
                      ) : null}
                    </div>
                  </div>
                  {/* <div className="col-12 col-sm-6">
                                        <div class="mb-3">
                                            <label for="" class="form-label">Your Email</label>
                                            <Field name="email" type="email" className="form-control" />
                                            {errors.email && touched.email ? (
                                                <div className="form_error">{errors.email}</div>
                                            ) : null}
                                        </div>
                                    </div> */}
                  <div className="col-12 col-sm-6">
                    <div class="mb-3">
                      <label for="" class="form-label">
                        Your Location *
                      </label>
                      <Field
                        placeholder="Location"
                        name="location"
                        type="text"
                        className="form-control"
                      />
                      {errors.location && touched.location ? (
                        <div className="form_error pt-1">{errors.location}</div>
                      ) : null}
                    </div>
                  </div>
                  {/* <div className="col-12">
                                        <div class="mb-3">
                                            <label for="" class="form-label">Comment </label>
                                            <textarea name="message" rows="5" class="form-control"></textarea>
                                        </div>
                                    </div> */}

                  <div className="col-12">
                    <div class="mb-3 text-center">
                      {!loader && (
                        <button className="btn apply_btn" type="submit">
                          Apply now
                        </button>
                      )}
                    </div>
                  </div>
                </div>

                {loader && (
                  <button
                    className="contact-submit-btn mt-4 d-flex align-items-center"
                    disabled
                  >
                    Loading<span class="btn-ring"></span>
                  </button>
                )}
                {emailSent && (
                  <div class="alert alert-success mt-2" role="alert">
                    Mail Sent Successfully. We will contact you shortly.
                  </div>
                )}
              </Form>
            )}
          </Formik>
        </Modal>
      </div>
    </>
  );
};

export default Loan;
