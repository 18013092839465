import React from "react";
import Header from "../../Components/Header/Header";
import Footer from "../../Components/Footer/Footer";
import "./Newsroom.css";
import cardImg from "../../assests/images/newsroom-card.png";
import quotesIcon from "../../assests/images/quotes_icon.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { api } from "../../Api/Api";
import { useEffect, useState } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";

const ViewNewsroom = () => {
  const [newsroomData, setnewsroomData] = useState(null);
  const { newsroomid } = useParams();
  console.log(newsroomid);
  const newsroomId = {
    newsRoomId: newsroomid,
  };
  useEffect(() => {
    getCareerData();
  }, []);
  const getCareerData = async () => {
    axios
      .request(api(`news/getOneNewsRoom`, "POST", newsroomId))
      .then(function (response) {
        setnewsroomData(response.data.data);
      })
      .catch(function (error) {
        console.error(error);
      });
  };

  const [newsroomCard, setnewsroomCard] = useState([]);

  useEffect(() => {
    getCategories();
  }, []);

  const getCategories = async () => {
    axios
      .request(api("news/allNewsRoom", "GET"))
      .then(function (response) {
        setnewsroomCard(response.data.data.list);
        // console.log(response.data);
        // console.log(response.data);
      })
      .catch(function (error) {
        console.error(error);
      });
  };

  return (
    <>
      <Header />  

      {/* Header section start */}
      {/* {console.log(data)} */}

      {newsroomData &&
        newsroomData &&
        newsroomData.map((item, index) => (
          <section className="">
            <div className="container">
              <div className="row py-5">
                <div className="blog_detail-head col-12 col-sm-8 m-auto text-center">
                  <div className="badge_txt">June 18, 2022</div>
                  <h3>{item.title}</h3>
                  <p>{item.shortDescription}</p>
                </div>
              </div>

              <div className="row">
                <div className="col-12">
                  <div className="blog_detail-card position-relative">
                    <div className="blog_detail-img">
                      <img src={item.imageUrl} alt="" className="w-100" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        ))}

      {/* Header section end */}

      {newsroomData &&
        newsroomData &&
        newsroomData.map((item, index) => (
          <section className="mb-3">
            <div className="container">
              <div className="row">
                <div className="col-12 col-sm-10 m-auto">
                  <div className="blog_detail-content">
                    <p>{item.description}</p>

                    {/* <h3>The Digital Assets For All</h3>
                    <p>
                      Appropriately empower dynamic leadership skills after
                      business portals. Globally my carminate interactive supply
                      chains with distinctive quality vectors global sources
                      services. Uniquely matrix economically sound value through
                      cooperative technology. Competently parallel task fully
                      researched data and enterprise process improvements.
                    </p>

                    <ul className="ps-4 mb-4 disc">
                      <li className="mb-3">
                        Dynamically target high-payoff intellectual capital for
                        customized
                      </li>
                      <li className="mb-3">
                        Interactively procrastinate high-payoff content
                      </li>
                      <li className="mb-3">
                        Credibly reinter mediate backend ideas for
                        cross-platform models
                      </li>
                    </ul> */}

                    {/* <h3>How do I know how secure is my wallet?</h3>
                    <p>
                      Bitcoin and blockchain technology introduce solutions for
                      real-world issues, seeking to help the unbanked
                      population, combat counterfeiting and improve cross-border
                      transactions to name a few. Even if we disregard the
                      notion of bitcoin as a speculative asset or store of
                      value, it is likely here to stay and disrupt many sectors.
                    </p>

                    <div className="quotes_card mb-4">
                      <span className="quotes_icon d-block mb-4">
                        <img src={quotesIcon} alt="" />
                      </span>
                      “For the longest time, computers have been associated with
                      work. Mainframes were for the Army, government agencies,
                      and then large companies. Workstations were for engineers
                      and software programmers. PCs were initially for other
                      white-collar jobs.”
                    </div>

                    <h3>The Digital Assets For All</h3>
                    <p>
                      Appropriately empower dynamic leadership skills after
                      business portals. Globally my carminate interactive supply
                      chains with distinctive quality vectors global sources
                      services. Uniquely matrix economically sound value through
                      cooperative technology. Competently parallel task fully
                      researched data and enterprise process improvements.
                    </p>

                    <ul className="ps-4 mb-4 decimal">
                      <li className="mb-3">
                        Dynamically target high-payoff intellectual capital for
                        customized
                      </li>
                      <li className="mb-3">
                        Interactively procrastinate high-payoff content
                      </li>
                      <li className="mb-3">
                        Credibly reinter mediate backend ideas for
                        cross-platform models
                      </li>
                      <li className="mb-3">
                        Credibly reinter mediate backend ideas for
                        cross-platform models
                      </li>
                    </ul> */}
                  </div>
                </div>
              </div>
            </div>
          </section>
        ))}

      <section className="mb-0 mt-2">
        <div className="container">
          <div className="row">
            <div className="col-12 text-center">
              <h3>Related Posts</h3>
            </div>
          </div>
        </div>
      </section>

      <section className="newsroom_list-content mt-5">
        <div className="container">
          <div className="row">
            {newsroomCard &&
              newsroomCard.slice(0, 3).map((item, index) => (
                <div className="col-12 col-sm-6 col-md-4" key={index}>
                  <div className="newsroom_list-card">
                    <div className="newsroom_list-img">
                      <img src={item.imageUrl} alt="" className="w-100" />
                    </div>
                    <div className="newsroom_list-content pt-3">
                      <h3 className="mb-2">{item.title}</h3>
                      <div className="mb-2 newsroom_list-date">
                        April 7, 2022
                      </div>
                      <p>{item.shortDescription}</p>
                      <Link
                        to={`/ViewNewsroom/${item._id}`}
                        className="d-flex align-items-center"
                      >
                        Read More{" "}
                        <span className="ms-2 d-inline-flex align-items-center justify-content-center">
                          <FontAwesomeIcon icon={faAngleRight} />
                        </span>{" "}
                      </Link>
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
};

export default ViewNewsroom;
