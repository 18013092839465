import React from "react";
import Header from "../../Components/Header/Header";
import Footer from "../../Components/Footer/Footer";
import "./Newsroom.css";
import cardImg from "../../assests/images/newsroom-card.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import { api } from "../../Api/Api";
import { useEffect, useState } from "react";
import axios from "axios";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import blogs_data from './Blogs.json'

const Newsroom = () => {
  const [categories, setCategories] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const itemsPerPage = 6;

  const [blogs, setBlogs] = React.useState([]);

  console.log("cchdfhfh", categories.list);

  // useEffect(() => {
  //   getCategories(currentPage);
  //   console.log('test', blogs)
  // }, [currentPage]);

  useEffect(() => {
    setBlogs(blogs_data.list);

  }, [])

  const getCategories = async (page) => {
    axios
      .request(api(`news/allNewsRoom?pageNo=${page}`, "GET"))
      .then(function (response) {
        setCategories(response.data.data);
        console.log(categories, "categories");
        setTotalPages(response.data.data.totalPage);
      })
      .catch(function (error) {
        console.error(error);
      });
  };

  const handlePageChange = (event, page) => {
    setCurrentPage(page);
    setCategories([]); // Clear the existing categories
  };

  const [newsroomCard, setnewsroomCard] = useState([]);

  useEffect(() => {
    getOneNewsRoom();
  }, []);


  const getOneNewsRoom = async () => {
    axios
      .request(api("news/allNewsRoom", "GET"))
      .then(function (response) {
        setnewsroomCard(response.data.data.list);
        console.log(response.data);
      })
      .catch(function (error) {
        console.error(error);
      });
  };

  return (
    <div>
      <Header />

      {/* <section className="">
        <div className="container">
          <div className="row py-5">
            <div className="col-12 text-center">
              <h3 className="fs-40">Newsroom</h3>
            </div>
          </div>
          {newsroomCard && newsroomCard.length > 0 && (
            <div className="row">
              <div className="col-12">
                <div className="newsroom_card-row">
                  <div className="row">
                    <div className="col-12 col-sm-12 col-md-6">
                      <div className="newsroom_card-img">
                        <img
                          src={newsroomCard[0].imageUrl}
                          alt=""
                          className="w-100"
                        />
                      </div>
                    </div>
                    <div className="col-12 col-sm-12 col-md-6">
                      <div className="newsroom_card-content h-100 d-flex align-items-start justify-content-center flex-column">
                        <h3>{newsroomCard[0].title}</h3>
                        <p>{newsroomCard[0].shortDescription}</p>
                        <p className="newsroom_card-date">
                          {newsroomCard[0].date}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </section> */}

      <section className="newsroom_list">
        <h3 className="fs-40 text-center pb-4">Newsroom</h3>

        {/* <div className="container">
          <div className="row">
            {categories.list &&
              categories.list.map((item, index) => (
                <div className="col-12 col-sm-6 col-md-4" key={index}>
                  <div className="newsroom_list-card">
                    <div className="newsroom_list-img">
                      <img src={item.imageUrl} alt="" className="w-100" />
                    </div>
                    <div className="newsroom_list-content pt-3">
                      <h3 className="mb-2">{item.title}</h3>
                      <div className="mb-2 newsroom_list-date">
                        April 7, 2022
                      </div>
                      <p>{item.shortDescription}</p>
                      <Link
                        to={`/ViewNewsroom/${item._id}`}
                        className="d-flex align-items-center"
                      >
                        Read More{" "}
                        <span className="ms-2 d-inline-flex align-items-center justify-content-center">
                          <FontAwesomeIcon icon={faAngleRight} />
                        </span>{" "}
                      </Link>
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </div> */}

        <div className="container">
          <div className="row">
            {blogs &&
              blogs.map((item, index) => (
                <div className="col-12 col-sm-6 col-md-4" key={index}>
                  <div className="newsroom_list-card ">
                    <div className="newsroom_list-img">
                      <img src={item.imageUrl} alt="" className="w-100" />
                    </div>
                    <div className="newsroom_list-content pt-3">
                      <h3 className="mb-2">{item.title}</h3>
                      <div className="mb-2 newsroom_list-date">
                       {item.createdAt}
                      </div>
                      <p>{item.shortDescription}</p>
                      <Link to={`/BlogDetail/${item._id}`} className="d-flex align-items-center">
                        Read More
                        <span className="ms-2 d-inline-flex align-items-center justify-content-center">
                          <FontAwesomeIcon icon={faAngleRight} />
                        </span>
                      </Link>
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </div>

        {/* <div className="container">
          <div className="row">
            {blogs.list &&
              blogs.list.map((item, index) => (
                <div className="col-12 col-sm-6 col-md-4" key={index}>
                  <div className="newsroom_list-card">
                    <div className="newsroom_list-img">
                      <img src={item.imageUrl} alt="" className="w-100" />
                    </div>
                    <div className="newsroom_list-content pt-3">
                      <h3 className="mb-2">{item.title}</h3>
                      <div className="mb-2 newsroom_list-date">
                        April 7, 2022
                      </div>
                      <p>{item.shortDescription}</p>
                      <Link
                        to={`/ViewNewsroom/${item._id}`}
                        className="d-flex align-items-center"
                      >
                        Read More{" "}
                        <span className="ms-2 d-inline-flex align-items-center justify-content-center">
                          <FontAwesomeIcon icon={faAngleRight} />
                        </span>{" "}
                      </Link>
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </div> */}

        {/* <div className="col-12">
          <div className="newsroom_list-pagination">
            <Stack spacing={2}>
              <Pagination
                count={totalPages}
                page={currentPage}
                shape="rounded"
                onChange={handlePageChange}
              />
            </Stack>
          </div>
        </div> */}
      </section>

      <Footer />
    </div>
  );
};

export default Newsroom;
